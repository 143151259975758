$(document).ready(function(){
    var browser = detect().browser;
    var classes = [];
        classes.push('br-' + browser.name.toLowerCase());
        classes.push('br-ver-' + parseInt(browser.version));

    $('body').addClass(classes.join(' '));

    if (browser.name.toLowerCase() === 'safari') {
        if ($('.article-item-ad').length > 0) {
            var height = $('.article-item-ad').height();
            $('.article-item-ad a').css('height', height);
        }
    }
});

$(document).ready(function() {
	// Navigation slide
	$(".navigation-switch").on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();

	    $(".nav").toggleClass("active-navigation");
        $('body').addClass('nav-opened');
	}),
	$(".nav-controls-close").on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();

	    $(".nav").removeClass("active-navigation");
        $('body').removeClass('nav-opened');
	})
});

$(document).ready(function(){
    // Search
    $('.search-switch').on('click', function(event){
        event.preventDefault();
        event.stopPropagation();

        $('.search').addClass('search-active');
        $('body').addClass('search-opened');
        window.location.hash = '#searchOpen';
    });

    $('.search-controls-close').on('click', function(event){
        event.preventDefault();
        event.stopPropagation();

        $('.search').removeClass('search-active');
        $('body').removeClass('search-opened');
        window.location.hash = '';
    });
});

$(document).ready(function(){
    // Language selector in nav
    $('.language-selector-switch').on('click', function(event){
        event.preventDefault();
        $('.languages').toggleClass('active');
    });
});

$(window).on('load', function(){
    if ($('.x-detail').length > 0) {
        var $share = $('.article .share');
        var $tags = $('.article .tags').first();
        var top = $(window).scrollTop();
        var limit = $share.offset().top - 50;
        var bottom = $('.article').offset().top + $('.article').outerHeight() - $(window).height();
        var bottomLimit = bottom - ($('.article').offset().top - $(window).height());

        if (top > limit && top < bottomLimit) {
            $share.addClass('share-sticky');
            $share.removeClass('share-sticky-bottom');
        }
        if (top >= bottomLimit) {
            $share.removeClass('share-sticky');
            $share.addClass('share-sticky-bottom');
        }
        if (top <= limit) {
            $share.removeClass('share-sticky');
            $share.removeClass('share-sticky-bottom');
        }

        $(window).on('scroll', function(){
            top = $(window).scrollTop();

            if (top > limit && top < bottomLimit) {
                $share.addClass('share-sticky');
                $share.removeClass('share-sticky-bottom');
            }
            if (top >= bottomLimit) {
                $share.removeClass('share-sticky');
                $share.addClass('share-sticky-bottom');
            }
            if (top <= limit) {
                $share.removeClass('share-sticky');
                $share.removeClass('share-sticky-bottom');
            }
        });
    }
});

/*$( document ).ajaxStop(function() {
    var $img_button = $('.cta_button');
    console.log($img_button);
    
    if ($img_button.length > 0) {
        $img_button.each(function() {
            $(this).parent().addClass('no-border'); 
        });
    }
});*/